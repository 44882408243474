/* Base styles */
.footer {
    display: flex;
    flex-direction: column;
}

.hoverOn{
    cursor: pointer;
}
.topSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #b7e9ff;
    color: #333;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid white;
}

.leftSide {
    font-size: 16px;
    font-weight: 750;
}

.rightSide {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.socialIcons {
    display: flex;
    gap: 15px;
}

.icon {
    color: #0056b3;
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.icon:hover {
    color: #0056b3;
}

.columns {
    display: flex;
    justify-content: space-between;
    padding: 60px;
    background-color: #5ac4e7;
    color: white;
}

.logoColumn {
    flex: 1;
    max-width: 300px; /* Set uniform max-width */
}

.logo {
    max-width: 100%;
}

.column {
    flex: 1;
    max-width: 300px; /* Ensure columns have uniform width */
    margin: 0 10px;
}

.column h3 {
    margin-top: 0;
    font-size: 18px;
    font-weight: 700;
}

.column ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.column li {
    margin-bottom: 10px;
    word-wrap: break-word; /* Allow long text to wrap to the next line */
}

.icon {
    color: white;
    font-size: 18px;
    margin-right: 8px;
}

/* New copyright section */
.copyrightRow {
    background-color: white;
    color: #333;
    text-align: center;
    padding: 10px;
    border-top: 1px solid #ccc;
}

.copyrightRow p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .topSection {
        flex-direction: column;
        text-align: center;
    }

    .leftSide {
        margin-bottom: 10px;
    }

    .columns {
        flex-direction: column;
        padding: 20px;
        text-align: left; /* Ensure content remains left-aligned */
        align-items: center; /* Center the columns */
    }

    .column {
        max-width: 100%; /* Make columns full width on small screens */
        margin-bottom: 20px;
    }

    .logo {
        max-width: 200px;
        margin-bottom: 20px;
    }

    .icon {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .columns {
        padding: 15px;
        text-align: left; /* Ensure content remains left-aligned */
        align-items: center; /* Center the columns */
    }

    .logo {
        max-width: 150px;
    }

    .column {
        max-width: 100%; /* Ensure all columns are full-width on mobile */
        margin-bottom: 20px;
    }

    .column h3 {
        font-size: 16px;
    }

    .column li {
        font-size: 14px;
        word-wrap: break-word; /* Ensure long text like emails wrap properly */
    }

    .icon {
        font-size: 18px;
    }
}