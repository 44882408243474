
/* CSS for the section FAQ Header */
.faqSection {
    display: flex;                /* Use flexbox for centering */
    flex-direction: column;      /* Stack items vertically */
    align-items: center;         /* Center items horizontally */
    justify-content: center;     /* Center items vertically */
    height: 50vh;                /* Set height to 50vh */
    background-color: #dbecfd;   /* Background color */
    margin: 50px auto;           /* Center the section */
    padding: 20px;               /* Padding for the section */
    max-width: 100%;             /* Max width */
}

.innerSection {
    max-width: 60%;
    text-align: center;          /* Center the text */
    margin: 0 auto;             /* Center the inner section */
}

.faqTitle {
    color: red;
    font-size: 1.5rem;           /* Default font size */
    font-weight: bold;
    margin-bottom: 10px;
}

.mainTitle {
    font-size: 2.5rem;           /* Default font size */
    font-weight: bold;
    margin-bottom: 20px;
}

.highlight {
    color: #1e90ff; /* Sky blue color */
}

.descrip {
    font-size: 1rem;              /* Default font size */
    color: #333;
    margin-top: 10px;
    line-height: 1.6;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .faqSection {
        padding: 10px;           /* Reduce padding on smaller screens */
    }

    .innerSection {
        max-width: 90%;          /* Allow more width on small screens */
    }

    .faqTitle {
        font-size: 1.2rem;       /* Reduce font size */
    }

    .mainTitle {
        font-size: 2rem;         /* Reduce font size */
    }

    .descrip {
        font-size: 0.9rem;       /* Reduce font size */
    }
}

@media (max-width: 480px) {
    .faqTitle {
        font-size: 1rem;         /* Further reduce font size for very small screens */
    }

    .mainTitle {
        font-size: 1.5rem;       /* Further reduce font size */
    }

    .descrip {
        font-size: 0.8rem;       /* Further reduce font size */
    }
}




