.container {
    background-color: #f0faff;
    padding-top: 20px;
    padding-bottom: 40px;
}

.wrapper {
    display: flex;
    flex-direction: row; /* Default to horizontal layout */
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px; /* Space between map and form */
    flex-wrap: wrap; /* Allows items to wrap to new lines on smaller screens */
}

.mapContainer {
    flex: 1; /* Grow and shrink with equal flex basis */
    min-width: 300px; /* Minimum width to ensure map is usable */
    padding-right: 20px;
}

.mapBox {
    width: 100%;
    height: 400px;
    border: 2px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.formContainer {
    flex: 1; /* Grow and shrink with equal flex basis */
    min-width: 300px; /* Minimum width to ensure form is usable */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    box-sizing: border-box;
}

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
    .wrapper {
        flex-direction: column; /* Stack map and form vertically */
        gap: 0; /* Remove gap between stacked items */
    }

    .mapContainer,
    .formContainer {
        width: 100%; /* Full width on small screens */
        padding-right: 0;
        padding-left: 0;
    }
}


:root {
    --map-width: 50%;
    --form-width: 50%;
}

.mapContainer {
    width: var(--map-width);
}

.formContainer {
    width: var(--form-width);
}

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
    .mapContainer,
    .formContainer {
        width: 100%;
    }
}
