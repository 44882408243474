.container {
  margin: 20px auto;
  width: 90%;
  font-family: Arial, sans-serif;
}

.tabBar {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allow wrapping on small screens */
  overflow-x: auto; /* Make it scrollable horizontally */
  scrollbar-width: thin; /* Firefox */
}

.tabBar button {
  padding: 10px 30px; /* Adjust padding for smaller screens */
  background: transparent;
  border: 2px solid transparent;
  cursor: pointer;
  font-size: 16px;
  color: black;
  margin: 5px 2px; /* Adjust margin for smaller spacing */
  border-radius: 5px;
  transition: background-color 0.3s, border 0.3s;
  white-space: nowrap; /* Prevent text from wrapping */
}

.tabBar button:hover {
  background-color: white;
  border: 2px solid #d4d4d4;
}

.tabBar button.activeTab {
  background-color: #005f99;
  border: 2px solid #005f99;
  color: white;
}

.contentContainer {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}

.specTable {
  width: 100%;
  border-collapse: collapse;
}

.specTable th, .specTable td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

.gridContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.gridItem {
  background-color: #f9f9f9;
  padding: 5px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 30%;
}

.itemImage {
  max-width: 200px;
  height: 200px;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .tabBar {
    justify-content: flex-start; /* Align the tabs to the left on small screens */
    overflow-x: auto; /* Horizontal scroll */
    flex-wrap: nowrap; /* Prevent wrapping */
  }

  .tabBar button {
    padding: 8px 20px; /* Reduce button padding for smaller screens */
    font-size: 14px; /* Smaller font for smaller screens */
  }

  .gridItem {
    width: 45%; /* Adjust grid item width for smaller screens */
  }
}

@media (max-width: 480px) {
  .tabBar button {
    padding: 6px 15px; /* Further reduce padding on very small screens */
    font-size: 12px; /* Reduce font size for extra small screens */
  }

  .gridItem {
    width: 100%; /* Full width for extra small screens */
  }
}
