.formContainer {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box; /* Ensures padding is included in the width */
  }
  
  .formField {
    margin-bottom: 15px;
  }
  
  .formField label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #000;
    margin-bottom: 5px;
  }
  
  .inputField, .selectField, .textareaField {
    width: 100%; /* Ensure fields take full width available */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f0faff;
    font-size: 14px;
    color: #000;
    margin: 5px 0; /* Consistent vertical margin */
    box-sizing: border-box; /* Ensures padding is included in the width */
  }
  
  .inputField:focus, .selectField:focus, .textareaField:focus {
    border-color: #70d3ff;
    outline: none;
  }
  
  .row {
    display: flex;
    gap: 15px;
  }
  
  .row > * {
    flex: 1; /* Each field in the row will take equal space */
  }
  
  .row input, .row select {
    flex: 1;
  }
  
  .textareaField {
    height: 100px;
  }
  
  .submitButton {
    width: 100%;
    padding: 12px;
    background-color: #70d3ff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px; /* Consistent margin from top */
  }
  
  .submitButton:hover {
    background-color: #007bff;
  }
  



  /* Full-screen overlay for the spinner */
  .spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Optional: For overlay effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensures it is on top of everything */
  }
  
  .spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  