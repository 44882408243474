














.navbar1 {
  background-color: white;
  color: black;
  padding: 1.5rem;
  position: relative;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Remove overflow hidden */
}


.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.left {
  display: flex;
  align-items: center;
}

.logo {
  width: 150px;
  height: auto;
  margin-right: 1rem;
  cursor: pointer;
}

.desktopLinks {
  display: flex;
}

.navLink {
  color: black;
  text-decoration: none;
  margin-right: 1.5rem;
}

.navLink:hover {
  text-decoration: none;
  color: #0074bd;
  font-weight: 600;
}

.right {
  display: flex;
  align-items: center;
  position: relative;
}

.contactLink {
  color: black;
  text-decoration: none;
  padding: 0.3rem 0.7rem;
  background-color: #0074bd;
  color: white;
  border-radius: 5px;
}

.contactLink:hover {
  background-color: #005ea2;
}

.hamburger {
  display: none;
  font-size: 2rem;
  cursor: pointer;
  /* margin-left: 1rem; */
}
/* Mobile Menu */
.mobileMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  position: fixed; /* Ensure it covers the whole screen */
  top: 140px; /* Position it right below the navbar */
  left: 0;
  right: 0;
  bottom: 20px; /* Slight space from the bottom */
  padding: 1rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1); /* Subtle border */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  overflow-y: auto; /* Scroll if content exceeds */
  margin-bottom: 0; /* Reset any bottom margin */
}

/* Navigation links inside mobile menu */
.mobileMenu .navLink {
  margin: 1rem 0;
  font-size: 1.25rem;
  color: black;
  text-decoration: none;
  width: 100%; /* Make the links span the full width */
  padding: 0.5rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Line under each link */
  text-align: center; /* Center align links */
}

/* Add a hover effect for better interaction */
.mobileMenu .navLink:hover {
  background-color: #f0f0f0;
}

/* Contact Us button styling */
.mobileMenu .contactLink {
  color: white;
  background-color: #0074bd;
  padding: 1rem 2rem;
  border-radius: 5px;
  font-size: 1.25rem;
  text-align: center;
  width: 100%;
}

.mobileMenu .contactLink:hover {
  background-color: #005ea2;
}

/* Line spacing under each link */
.mobileMenu .navLink:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.mobileMenu .navLink {
  margin-left: 10px; /* Add some space from the left */
  margin-right: 10px; /* Add some space from the right */
}

/* Hide the desktop links on mobile */
@media (max-width: 768px) {
  .desktopLinks {
    display: none;
  }
  .contactLink{
    display: none;
  }

  .hamburger {
    display: block;
    margin-right: 20px;

  }

}

/* For screens under 600px */
@media (max-width: 600px) {
  .container {
    padding: 0 1rem;
  }
}
















.navbar1 {
  position: relative;
  z-index: 1000; /* High enough to keep the dropdown on top */
}


/* Dropdown styles */
.dropdownContainer {
  position: relative;
}

.dropdown {
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  margin-top: 0;
  padding: 0.5rem 0;
  left: 0;
}

.dropdownItem {
  display: block;
  padding: 0.5rem 1rem;
  color: black;
  text-decoration: none;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}






/* Grid system for three-column dropdown */
.dropdownGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 10px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

@media screen and (max-width: 1100px) {
  .dropdownGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 10px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
}

.dropdownItem {
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 66px;
  border-radius: 7px;
  background-color: rgb(235, 233, 233);
}

.dropdownImage {
  width: 70px;
  height: 70px;
  border-radius: 2px;
  margin-right: 10px;
}
.dropdownLinkTitle{
  text-decoration: none;
  color: black;
  /* text should be in one line only */
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
}

 

.dropdownContainer:hover .dropdownGrid {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
}
