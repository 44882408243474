.container {
    width: 100%;
    max-width: 1200px; /* Set max-width for large screens */
    margin: 0 auto; /* Center the container */
    padding: 0 10px; /* Add some padding */
}

.title {
    text-align: center;
    color: #1b80ad;
    margin: 20px 0;
}

.scrollableContainer {
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    /* Center the scrollable content */
    margin: 0 auto;
}

.scrollContent {
    display: flex;
    animation: scroll 1400s linear infinite; /* Adjust the speed of scrolling */
}

.boxes {
    background-color: #ade4f5;
    border-radius: 10px;
    border: 1px solid transparent;
    padding: 10px;
    opacity: 90%;
    cursor: pointer;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* Increase width and height */
    width: 150px;
    height: 150PX;
}

.image {
    width: 100%; /* Take up the full width of the container */
    height: 100%; /* Take up the full height of the container */
    object-fit: cover; /* Ensure the image covers the container without stretching */
}

.title {
    margin-top: 10px;
    font-weight: bold;
}

/* Keyframes for scrolling animation */
@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%); /* Adjust to match the total width of content */
    }
}

/* Responsive styles */
@media (max-width: 768px) {
    .scrollContent {
        animation: scroll 1480s linear infinite; /* Adjust the speed for smaller screens */
    }

 

    .boxes {
        width: 150px; /* Adjust width for smaller screens */
        height: 150px; /* Adjust height for smaller screens */
    }
}

@media (max-width: 480px) {
    .scrollContent {
        animation: scroll 1400s linear infinite; /* Further adjust speed for very small screens */
    }


    .boxes {
        width: 120px; /* Adjust width for very small screens */
        height: 120px; /* Adjust height for very small screens */
    }
}
