.howToOrderContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap; /* Ensures the content wraps on smaller screens */
  }
  
  .leftSection, .rightSection {
    flex: 1;
    border: 2px solid #f0faff;
    border-radius: 10px;
    background-color: white;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center content */
    align-items: center;
    box-sizing: border-box; /* Ensure padding is included in width calculation */
    min-height: 400px; /* Ensure both sections have equal height */
  }
  
  .imageGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Always 2 images per row */
    gap: 20px;
    width: 100%; /* Ensure the grid takes up the full width */
  }
  
  .image {
    height: 100%; /* Ensure the image takes the full height of the grid cell */
    width: 100%; /* Ensure the image takes the full width of the grid cell */
    object-fit: cover; /* Fit images within their container without distortion */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .rightSection {
    font-size: 1.1rem;
    line-height: 1.6;
    display: flex;
    flex-direction: column; 
    justify-content: flex-start; /* Align content at the top */
  }
  
  
  ol {
    margin-top: 10px;
    padding-left: 20px;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .howToOrderContainer {
      flex-direction: column; /* Stack left and right sections vertically */
      align-items: center;
    }
  
    .leftSection, .rightSection {
      padding: 20px; /* Reduce padding on smaller screens */
      min-height: auto; /* Remove min-height on smaller screens for flexibility */
      width: 100%; /* Ensure both sections take full width on small screens */
    }
  
    .imageGrid {
      grid-template-columns: repeat(2, 1fr); /* Keep 2 images per row */
      gap: 15px;
    }
  
    .image {
      height: auto; /* Adjust image height to fit container better */
      max-height: 200px; /* Adjust image height for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .howToOrderContainer {
      flex-direction: column;
    }
  
    .leftSection, .rightSection {
      padding: 15px; /* Further reduce padding for smaller screens */
      width: 100%; /* Make sure they take up full width */
    }
  
    .imageGrid {
      grid-template-columns: repeat(2, 1fr); /* Keep 2 images per row even on very small screens */
      gap: 10px;
    }
  
    .image {
      height: auto;
      max-height: 150px; /* Further reduce image height */
    }
  
    h1 {
      font-size: 1.5rem; /* Adjust heading size on small screens */
    }
  }
  