.title {
    text-align: center;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
    color: #215a96;
    font-size: 2rem; /* Adjust font size for title */
  }
  
  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contentBox {
    background-color: #e0f7ff; /* Light sky blue background */
    border-radius: 12px;
    padding: 20px;
    width: 100%;
    max-width: 1300px;
    margin-top: 20px;
    max-height: 500px; /* Set a maximum height for the content box */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  .content {
    text-align: left;
    color: #333;
    font-size: 1rem; /* Default font size for content */
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .title {
      font-size: 1.5rem; /* Smaller font for title */
      margin-top: 20px;
      margin-bottom: 20px;
    }
    
    .contentBox {
      padding: 15px;
      max-width: 90%; /* Reduce width on medium screens */
      max-height: 300px; /* Adjust max height for medium screens */
    }
    
    .content {
      font-size: 0.9rem; /* Adjust font size for content */
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 1.2rem; /* Even smaller font for very small screens */
      margin-top: 15px;
      margin-bottom: 15px;
    }
  
    .contentBox {
      padding: 10px;
      max-width: 100%; /* Full width on small screens */
      max-height: 200px; /* Adjust max height for small screens */
    }
  
    .content {
      font-size: 0.85rem; /* Smaller font size for content */
    }
  }
  