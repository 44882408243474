.title {
  color: #1b80ad;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; /* Adjust as needed */
}

.seeAllButton {
  background-color: #1ea9d3;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.seeAllButton:hover {
  background-color: #1799c6;
}

.cardContainer {
  display: flex;
  overflow-x: scroll; /* Allow horizontal scrolling */
  cursor: pointer;
  padding: 10px 0;
  scroll-behavior: smooth;
  /* Hide scrollbar for Webkit browsers */
  -ms-overflow-style: none;  /* Hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;     /* Hide scrollbar for Firefox */
  gap: 16px; /* Adjust the value as needed */
}

.cardContainer::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

/* Flex container for card to ensure alignment */
.card {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: space-between; /* Align content to fill the space */
  background-color: white;
  border-radius: 7px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 0 0 auto;
  width: 250px; /* Default width */
  text-align: center;
  padding: 16px;
  height: 350px; /* Default height */
}

.cardImage {
  width: 100%;
  height: 240px; /* Default height */
  border-radius: 7px;
  margin-bottom: 8px;
}

/* Adjust card width and height for smaller screens */
@media (max-width: 768px) {
  .card {
    width: 200px; /* Adjust width for smaller screens */
    height: 300px; /* Adjust height for smaller screens */
  }

  .cardImage {
    height: 180px; /* Adjust height for smaller screens */
  }

  .cardTitle {
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .card {
    width: 150px; /* Further adjust width for very small screens */
    height: 250px; /* Further adjust height for very small screens */
  }

  .cardImage {
    height: 120px; /* Further adjust height for very small screens */
  }

  .cardTitle {
    font-size: 14px; /* Further adjust font size for very small screens */
  }
}

.cardTitle {
  color: black;
  font-size: 18px; /* Default font size */
  margin: 8px 0;
}

.getQuoteButton {
  background-color: #1ea9d3;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin-top: auto; /* Pushes the button to the bottom */
}

.getQuoteButton:hover {
  background-color: #1799c6;
}
