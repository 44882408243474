/* src/components/Container.module.css */
.container {

    width: 100%;
    max-width: 1400px; /* Adjust to your preferred width */
    margin: 0 auto; /* Center the container */
    padding: 0 15px; /* Optional padding */
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .container {
        padding: 0 20px; /* Adjust padding for smaller screens */
    }
}

@media (max-width: 480px) {
    .container {
        padding: 0 10px; /* Further adjust padding for very small screens */
    }
}
