
.container {
    display: flex;
    flex-direction: row;
    height: 100vh;
  }
  
  .imageContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .imageText {
    margin-top: 20px;
    text-align: center;
    font-size: 1.2em;
    color: #555;
  }
  
  .formContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .formText {
    margin-top: 20px;
    text-align: center;
    font-size: 1em;
    color: #555;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      height: auto;
    }
  
    .image {
      width: 100%;
      height: auto;
    }
  
    .formContainer,
    .imageContainer {
      width: 100%;
      padding: 10px;
    }
  }
  