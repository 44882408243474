.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.titleMain {
  color: #1b80ad;
}

.viewAllButton {
  background-color: #1ea9d3;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

/* Carousel container styling */
/* Carousel container styling */
.carousel {
  width: 100%;
  overflow: hidden;
}

.innerCarousel {
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
  animation: scroll 800s linear infinite;
  animation-play-state: running; /* Animation runs by default */
}

.innerCarousel:hover {
  animation-play-state: paused; /* Pause animation on hover */
}

.innerCarousel::-webkit-scrollbar {
  display: none;
}

/* Cart styling */
.cart {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 0 10px;
  width: 200px;
  height: 300px;
  flex-shrink: 0;
  position: relative;
  margin-bottom: 20px;
}

.image {
  width: 100%;
  height: auto;
  border-radius: 7px;
}

.title {
  margin: 15px 0;
  text-align: center;
}

.button {
  background-color: #1ea9d3;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  position: absolute;
  bottom: 10px;
  cursor: pointer;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .cart {
    width: 150px;
    height: 230px;
    padding: 15px;
  }

  .image {
    width: 150px;
  }

  .title {
    font-size: 14px;
  }

  .button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .cart {
    width: 120px;
    height: 195px;
    padding: 10px;
  }

  .image {
    width: 120px;
  }

  .title {
    font-size: 12px;
  }

  .button {
    padding: 6px 12px;
    font-size: 12px;
  }
}

/* Keyframes for the scrolling animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}
