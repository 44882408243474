.heading {
    color: #0e87d2;
    text-align: left;
    font-size: 2em;
    margin-bottom: 10px;
}

.description {
    text-align: left;
    font-size: 1.2em;
    margin-bottom: 20px;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.leftSide {
    width: 60%;
}

.largeImage {
    width: 100%; /* Make image responsive */
    height: auto;
    margin-bottom: 20px;
}

.smallImages {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.smallImage {
    width: 23%;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.smallImage:hover {
    transform: scale(1.1);
}

.features {
    display: flex;
    flex-direction: column;
}

.features p {
    margin: 5px 0;
    font-size: 1em;
}

.tick {
    color: green;
    font-size: 1.2em;
    margin-right: 10px;
}

.rightSide {
    width: 35%;
}

/* Adjustments for tablet screens */
@media (max-width: 1024px) {
    .leftSide, .rightSide {
        width: 100%; /* Stack content vertically */
        margin-bottom: 20px;
    }

    .largeImage {
        width: 100%;
        height: auto; /* Ensure the large image scales on tablet */
    }
}

/* Adjustments for mobile screens */
@media (max-width: 768px) {
    .heading {
        font-size: 1.5em;
    }

    .description {
        font-size: 1em;
    }

    .content {
        flex-direction: column; /* Stack the content vertically */
        align-items: center;
    }

    .leftSide, .rightSide {
        width: 100%; /* Make both sides full width on mobile */
    }

    .smallImage {
        width: 48%; /* Smaller images take up more width on mobile */
        margin-bottom: 10px;
    }

    .features p {
        font-size: 0.9em; /* Smaller font size on mobile */
    }
}

/* Scroller */
.Scrollercontainer {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    height: 65vh; /* Full viewport height */
    width: 100%;
    background: 
        linear-gradient(to right, #f4f4f4 65%, transparent 65%), /* Light grey on the left 60% */
        repeating-linear-gradient(-30deg, 
            #f4f4f4 0, 
            #f4f4f4 60px, 
            #0d68a1 70px, 
            #0d68a1 130px
        ); /* White and blue diagonal stripes */
    border: 1px solid #ddd;
    border-radius: 8px;
}

.scrollableDescription {
    max-height: 300px; 
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 45px;
    padding-right: 23px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 15px;
}

.scrollableDescription::-webkit-scrollbar {
    width: 10px;
}

.scrollableDescription::-webkit-scrollbar-thumb {
    background-color: #0d68a1;
    border-radius: 10px;
}








