.navbar {
    background-color: #0074bd;
    padding: 0.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .email {
    color: white;
    text-decoration: none;
  }
  
  .rightSide {
    display: flex;
    gap: 1rem; /* Space between the phone and "Get a Quote" */
  }
  
  .phone,
  .quote {
    color: white;
    text-decoration: none;
  }
  
  /* Media query for screens 600px and below */
  @media (max-width: 600px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  
    .email {
      margin-bottom: 0.1rem; /* Reduce the space between rows */
      text-align: center;
    }
  
    .rightSide {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1rem; /* Keep the spacing between phone and quote */
    }
  }
  