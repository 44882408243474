.headingContainer {
    padding: 20px;
    /* background-color: #f4f4f4; */
    background-color: white;
}

.heading {
    font-size: 2rem;
    color: RGB(0, 94, 162);
    margin-top: 20px;
}

.description {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 20px;
}

.imageSection {
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
}

.bigImage {
    width: 100%;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 7px;
}

.productSection {
    /* background-color: #f4f4f4; */
}

.productHeading {
    color: #0d68a1;
    margin-bottom: 20px;
}

.imageGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px 10px;
    
    margin-bottom: 45px;
}


.imageCard {
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    max-height: 250px;
}

p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.customizeButton {
    background-color: transparent;
    border: 1px dashed #0d68a1;
    color: #0d68a1;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.customizeButton:hover {
    background-color: #0d68a1;
    color: white;
    border: none;
}


/* Scroller */
.Scrollercontainer {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    height: 65vh; /* Full viewport height */
    width: 100%;
    background: 
        linear-gradient(to right, #f4f4f4 65%, transparent 65%), /* Light grey on the left 60% */
        repeating-linear-gradient(-30deg, 
            #f4f4f4 0, 
            #f4f4f4 60px, 
            #0d68a1 70px, 
            #0d68a1 130px
        ); /* White and blue diagonal stripes */
    border: 1px solid #ddd;
    border-radius: 8px;
}


.scrollableDescription {
    max-height: 300px; 
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 45px;
    padding-right: 23px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 15px;
}

.scrollableDescription::-webkit-scrollbar {
    width: 10px;
}

.scrollableDescription::-webkit-scrollbar-thumb {
    background-color: #0d68a1;
    border-radius: 10px;
    
}






/* See more */
.seeMoreContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px; /* Adjust space from products */
    margin-bottom: 40px; /* Adjust space from the bottom */
  }
  
  .seeMoreButton {
    padding: 10px 20px;
    background-color: #1c89e2; /* Customize the button color */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .seeMoreButton:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  





