.container {
    width: 80%;
    margin: auto;
    padding: 20px;
  }
  
  .header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .header h2 {
    color: #cc1c1c;
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .header h3 {
    color: #215a96;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .header p {
    font-size: 1rem;
    color: #333;
  }
  
  .faqGrid {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .faqColumn {
    width: 48%;
  }
  
  .faqItem {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    margin-bottom: 20px;
  }
  
  .question {
    font-weight: bold;
    font-size: 1.2rem;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .question:hover {
    color: #215a96;
  }
  
  .answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, padding 0.3s ease;
  }
  
  .open {
    padding-top: 10px;
  }
  
  .closed {
    max-height: 0;
    padding-top: 0;
  }
  