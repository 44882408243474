/* Header Section */
.headerContainer {
    background-color: #0d68a1;
    color: white;
    padding: 50px 0;
    text-align: center;
}

.mainHeading {
    font-size: 40px;
    font-weight: 900;
    margin-bottom: 10px;
    color: white;
}

.subHeading {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #cce5ff;
}

.description {
    font-size: 18px;
    line-height: 1.5;
    color: #ffffffb3;
    max-width: 800px;
    margin: 0 auto;
}


/* Image Section */
.imageSection {
    padding: 40px 0;
    background-color: #f9f9f9;
}

.categoryHeading {
    font-size: 36px;
    color: #0d68a1;
    margin-bottom: 20px;
    text-align: center;
}

.imageGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
}

/* Ensures all image cards have equal dimensions and are responsive */
.imageCard {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 96%; /* Ensures all image cards are of the same height */
}


/* Ensures images are uniform in size and maintain aspect ratio */
.image {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensures the image covers the container */
    border-radius: 8px;
    max-height: 250px; /* Restricting the max height of the images */
}

/* Ensuring images are square with same width and height */
@media (min-width: 769px) {
    .image {
        height: 250px; /* Ensure height and width are the same */
        width: 100%; /* Maintain width, adjusting with the container */
    }
}

/* For smaller screens, adjust the size dynamically */
@media (max-width: 768px) {
    .image {
        max-height: 200px;
        object-fit: cover; /* Ensures images fill the space properly while keeping the aspect ratio */
    }
}

p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.customizeButton {
    background-color: transparent;
    border: 1px dashed #0d68a1;
    color: #0d68a1;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.customizeButton:hover {
    background-color: #0d68a1;
    color: white;
    border: none;
}

/* Ensuring responsiveness */
@media (max-width: 768px) {
    .mainHeading {
        font-size: 32px;
    }

    .subHeading {
        font-size: 24px;
    }

    .description {
        font-size: 16px;
    }

    .categoryHeading {
        font-size: 28px;
    }
}

@media (max-width: 480px) {
    .mainHeading {
        font-size: 28px;
    }

    .subHeading {
        font-size: 20px;
    }

    .description {
        font-size: 14px;
    }

    .categoryHeading {
        font-size: 24px;
    }

    .image {
        max-height: 200px; /* Smaller images on small devices */
    }
}