.landingPage {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.imageContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlayImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 600px; /* Set a maximum height for larger screens */
  box-sizing: border-box;
}

/* Media query for larger screens like laptops */
@media (min-width: 768px) {
  .overlayImage {
    max-height: 500px; /* Adjust height for larger screens */
  }
}

/* Media query for smaller screens like mobile devices */
@media (max-width: 767px) {
  .overlayImage {
    max-height: 300px; /* Adjust height for smaller screens */
  }
}



/* marqeeee */

.marqueeContainer {
  background-color: #009688;
  height: 50px; /* Default height */
  color: white;
  font-size: 25px; /* Default font size */
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marqueeText {
  color: white;
  font-weight: bold;
}

.marqueeText b:first-child {
  color: black;
}

.marqueeText b:last-child {
  color: yellow;
}

/* For smaller screens (mobile devices) */
@media (max-width: 767px) {
  .marqueeContainer {
    height: 35px; /* Smaller height for mobile */
    font-size: 18px; /* Smaller text size for mobile */
    padding-top: 5px;
  }
}

/* For larger screens (tablets/laptops) */
@media (min-width: 768px) and (max-width: 1024px) {
  .marqueeContainer {
    height: 45px; /* Adjusted height for tablets */
    font-size: 20px; /* Adjusted text size for tablets */
    padding-top: 8px;
  }
}

/* For extra large screens (desktops) */
@media (min-width: 1025px) {
  .marqueeContainer {
    height: 50px; /* Larger height for desktops */
    font-size: 25px; /* Larger text size for desktops */
    padding-top: 10px;
  }
}
